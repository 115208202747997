#work {
    font-family: 'Montserrat', sans-serif;
    color: #dde1ed !important;
}

.work-title {
    padding-top: 40px;
    padding-bottom: 30px;
    
}

.work-content {
    border-top: 1px solid gray;
}

#work img {
    padding-top: 50px;
    padding-bottom: 15px;
}