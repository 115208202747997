#projects {
    /* background-image: linear-gradient(#5ED0A5, #3EAAA2); */
    padding: 45px 0px 150px;
    font-family: 'Montserrat', sans-serif;
    color: #dde1ed;
}
#pTitle {
    opacity: 0;
    transition: 0.8s;
}

#pTitle {
    margin-bottom: 50px;
}

.visible-projects {
    opacity: 1 !important;
    transform: (-10px, 0px);
    transition: transform 0.8s;
    margin-top: 20px !important;
}

.margin-v-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.skill {
    width: 20px !important;
    height: auto;
}

#projects .project-card {
    border: 1px solid #333;
    padding-left: 0px !important;
    padding-right: 0px !important;
    box-shadow: 4px 4px 40px 10px rgba(0, 0, 0, 0.2), -1em -1em 40px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 30px 5px !important;
    background-color: #475466 !important;
}

@media screen and (min-width: 769px) {
    .secondColumn {
        margin-top: 150px;
    }
}

#projects .project-title {
    margin-top: 10px;
}

#projects .project-description {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    font-size: 14px !important;
}

#projects img {
    width: 100%;
    height: auto !important;
    border-radius: 5px 5px 0px 0px;
}

#projects img.skill {
    width: 25px !important;
    height: auto;
    padding-right: 5px !important;
    padding-bottom: 10px !important;
}

#projects a {
    color: #DCDCDC !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.bg-mystic {
    background-color: #dde1ed !important;
}

.bg-riverbed {
    background-color: #475466 !important;
}

.bg-swirl {
    background-color: #d2c9c3 !important;
}