#about img {
    width: 250px;
    height: 250px;
    /* border-radius: 50%; */
    margin-top: -10px;
}

#about {
    /* background-image: linear-gradient(#2E408C, #496AA4); */
    font-family: 'Montserrat', sans-serif;
    color: #dde1ed !important;
    padding: 70px 0px 135px;
}

#aboutAvatar {
    opacity: 0;
    right: 50px;
    transition: 0.8s;
}

.avatar-visible {
    opacity: 1 !important;
    /* right: 0px !important; */
    transform: translate(50px, 0px);
    transition: transform 0.8s ease-in-out;
}

#aboutDescription {
    opacity: 0;
    margin-top: -50px;
    transition: 0.8s;
}

.about-visible {
    opacity: 1 !important;
    /* margin-top: 0px !important; */
    transform: translate(0px, 50px);
    transition: transform 0.8s ease-in-out;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.padding-bot-15 {
    padding-bottom: 15px !important;
}

