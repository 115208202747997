.jumbotron.jumbotron-fluid {
    background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(../../images/japanImg.jpeg);
    height: 100vh;
    width: 100%;
	color: black;
	background-size: cover;
	background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: bottom center;
    /* border-bottom-left-radius: 40% 20%;
    border-bottom-right-radius: 40% 20%; */
    /* background-color: purple; */
    z-index: 1;
    margin-bottom: 0px !important;
}

.header {
    background-color: #2E408C;
}

.row.justify-content-start {
    padding: 150px 0px;
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.header a {
    transition: 0.5s !important;
}

.header a:hover {
    font-size: 20px;
    transition: 0.5s;
}