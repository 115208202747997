#footer {
    /* background-image: linear-gradient(#496AA4, #2E408C); */
    padding: 35px 0px;
    z-index: 2;
    font-family: 'Courier New', Courier, monospace;
    color: white;
}

.more-msg {
    margin-top: -90px;
    color: white;
}

#footer h1 {
    padding-left: 15px;
}

#footer a {
    color: white;
    margin-left: 25px;
}

#link {
    padding-bottom: 20px;
}

.white {
    color: white !important;
}

.footer-content {
    margin-top: 50px !important;
}

.fa-icon:hover {
    margin-top: -5px !important;
    transition: .2s !important;
}